import * as React from 'react'
import { app_dns, app_email, app_name, app_phone, app_slogan, app_slogan_1, app_slogan_2 } from '../utils/utils'
import footershape from '../assets/images/shape/footer-shape.png'
import footerbg from '../assets/images/footer-bg.jpg'
import logo from '../assets/images/logo.png'

export const FooterCompoent = () => {

    const getTodayVisits = () => {
        const today = new Date().toISOString().slice(0, 10);
        const visits = JSON.parse(localStorage.getItem('visits')) || {};
        return visits[today] || 0;
    };

    React.useEffect(() => {
        const today = new Date().toISOString().slice(0, 10); // Format YYYY-MM-DD
        const visits = JSON.parse(localStorage.getItem('visits')) || {};
        visits[today] = (visits[today] || 0) + 1;
        localStorage.setItem('visits', JSON.stringify(visits));
        // console.log(`Nombre de visites aujourd'hui : ${visits[today]}`);
    }, []);

    return (
        <footer
            id="footer"
            className="footer-area bg_cover"
            style={{ backgroundImage: `url(${footerbg})` }}
        >
            <div className="footer-shape">
                <img src={footershape} alt="footer shape" />
            </div>
            {/* footer shape */}
            <div className="container">
                <div className="footer-widget pt-30 pb-70">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 order-sm-1 order-lg-1">
                            <div
                                className="footer-about pt-40 wow fadeInLeftBig"
                                data-wow-duration="1s"
                                data-wow-delay="0.3s"
                            >
                                <a href="#">
                                    <img src={logo} alt="Logo" />
                                </a>
                                <p className="text">
                                    {String(app_slogan_1).substring(0, app_slogan_1.indexOf("."))}
                                </p>
                                <p className="text">
                                    {app_slogan}
                                </p>
                            </div>
                            {/* footer about */}
                        </div>
                        <div className="col-lg-3 col-sm-6 order-sm-3 order-lg-2">
                            <div
                                className="footer-link pt-40 wow fadeInLeftBig"
                                data-wow-duration="1s"
                                data-wow-delay="0.5s"
                            >
                                <div className="footer-title">
                                    <h5 className="title">Nos services</h5>
                                </div>
                                <ul>
                                    <li>
                                        <a href="#">Conception de sites Web</a>
                                    </li>
                                    <li>
                                        <a href="#">Conception graphique</a>
                                    </li>
                                    <li>
                                        <a href="#">Consultance</a>
                                    </li>
                                    <li>
                                        <a href="#">Applications ( Web & Mobile )</a>
                                    </li>
                                </ul>
                            </div>
                            {/* footer link */}
                        </div>
                        <div className="col-lg-3 col-sm-6 order-sm-4 order-lg-3">
                            <div
                                className="footer-link pt-40 wow fadeInLeftBig"
                                data-wow-duration="1s"
                                data-wow-delay="0.7s"
                            >
                                <div className="footer-title">
                                    <h5 className="title">A propos de nous</h5>
                                </div>
                                <ul>
                                    <li>
                                        <a href="#about">Overview</a>
                                    </li>
                                    <li>
                                        {/* <a href="#">Why Us</a> */}
                                    </li>
                                    <li>
                                        <a href="#projects">Nos produits</a>
                                    </li>
                                    <li>
                                        <a href="#">Visites {getTodayVisits()}</a>
                                    </li>
                                </ul>
                            </div>
                            {/* footer link */}
                        </div>
                        <div className="col-lg-3 col-sm-6 order-sm-2 order-lg-4">
                            <div
                                className="footer-contact pt-40 wow fadeInLeftBig"
                                data-wow-duration="1s"
                                data-wow-delay="0.9s"
                            >
                                <div className="footer-title">
                                    <h5 className="title">Nos contacts</h5>
                                </div>
                                <div className="contact pt-10">
                                    <p className="text">
                                        Goma, NK, Rép. Dém. du Congo <br />
                                    </p>
                                    <p className="text">{app_email}</p>
                                    <p className="text">{app_phone}</p>

                                    <ul className="social mt-40">
                                        <li>
                                            <a href="#">
                                                <i className="lni-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="lni-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="lni-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="lni-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* contact */}
                            </div>
                            {/* footer contact */}
                        </div>
                    </div>
                    {/* row */}
                </div>
                {/* footer widget */}
                <div className="footer-copyright text-center">
                    <p className="text">
                        © {new Date().getFullYear()} Crafted by{" "}
                        <a href={app_dns} rel="nofollow">
                            {app_name}
                        </a>{" "}
                        All Rights Reserved.
                    </p>
                </div>
            </div>
            {/* container */}
        </footer>
    )
}