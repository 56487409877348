import * as React from 'react'
import { AboutComponent } from './about'
import { ServicesComponent } from './services'
import { ProjectsComponent } from './projects'
import { HomeComponent } from './home'

export const MainComponent = () => {
    return (
        <>
            <HomeComponent />
            <ServicesComponent />
            <AboutComponent />
            <ProjectsComponent />
        </>
    )
}