import * as React from "react";
import logo from "../assets/images/logo.png";
import { app_email, app_name } from "../utils/utils";
import { HomeComponent } from "./home";
import { Link as NavLink } from "react-scroll";

export const HeaderComponent = () => {
    React.useEffect(() => { }, []);

    return (
        <header className="header-area">
            <div className="navbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <img src={logo} alt={app_name} />
                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>

                                <div
                                    className="collapse navbar-collapse sub-menu-bar"
                                    id="navbarSupportedContent"
                                >
                                    <ul id="nav" className="navbar-nav m-auto">
                                        <li className="nav-item">
                                            <NavLink
                                                smooth={true}
                                                duration={500}
                                                spy={true} // Active la détection de la section visible
                                                activeClass="activator" // Classe à appliquer au lien actif
                                                to="home"
                                                className="page-scroll"
                                            >
                                                Acceuil
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                activeClass="activator"
                                                to="services"
                                                className="page-scroll"
                                            >
                                                Nos services
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                activeClass="activator"
                                                to="about"
                                                className="page-scroll"
                                            >
                                                À propos de nous
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                activeClass="activator"
                                                to="projects"
                                                className="page-scroll"
                                            >
                                                Nos produits
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="navbar-btn d-none d-sm-inline-block">
                                    <a
                                        className="main-btn"
                                        data-scroll-nav="0"
                                        href={`mailto:${app_email}`}
                                    >
                                        Nous contacter
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
