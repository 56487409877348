import * as React from 'react'
import logo from '../assets/images/logo.png'
import headerhero from '../assets/images/header-hero.jpg'
import imagehero from '../assets/images/hero-image.png'
import headershape from '../assets/images/shape/header-shape.png'
import { app_email, app_name, app_slogan, app_slogan_1 } from '../utils/utils'

export const NotFoundComponent = () => {
    return (
        <div id="home"
            className="header-hero bg_cover d-lg-flex align-items-center"
            style={{ backgroundImage: `url(${headerhero})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="header-hero-content">
                            <h1 className="hero-title wow fadeInUp"
                                data-wow-duration="1s"
                                data-wow-delay="0.2s">{"404"}</h1>
                            <p className="text wow fadeInUp"
                                data-wow-duration="1s"
                                data-wow-delay="0.5s">{"OUP! Il semble que la page que vous cherchez n'existe pas, reournez à la page d'acceuil"}</p>
                            <div className="header-play wow fadeInUp"
                                data-wow-duration="1s"
                                data-wow-delay="0.8s">
                                <a className="play-btn"
                                    href="/">
                                    Page d'acceuil
                                    <i className="lni-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- row --> */}
            </div>
            {/* <!-- container --> */}
            <div
                className="header-hero-image d-flex align-items-center wow fadeInRightBig"
                data-wow-duration="1s" data-wow-delay="1.1s">
                <div className="image">
                    <img src={imagehero}
                        alt="Hero Image" />
                </div>
            </div>
            {/* <!-- header hero image --> */}
            <div className="header-shape">
                <img src={headershape} alt="shape" />
            </div>
        </div>
    );
};
