import './App.css';
import { FooterCompoent } from './components/footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HeaderComponent } from './components/header';
import { MainComponent } from './components/main';
import { PreloaderComponent } from './components/preloader';
import { NotFoundComponent } from './components/notfound';

function App() {
  return (
    <>
       <Router>
       <PreloaderComponent/>
       <HeaderComponent />
      <Routes>
        <Route path="/" element={<MainComponent/>} />
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </Router>
      <FooterCompoent/>
    </>

  );
}

export default App;
