import { app_dns, app_name, app_ns, app_short_name } from "./utils";

export const __projects = [
    {
        key: 1,
        image: "https://cetuk.co.uk/wp-content/uploads/2023/11/Image_20231111122702.jpg",
        project_name: "TijaPro",
        project_description: "Une application de gestion de production dans un etablissament de production comme dans une usine",
        project_domaine: ("https://tijapro.").concat(app_ns),
        auther: (app_short_name).concat(" ").concat(app_name)
    },
    {
        key: 2,
        image: "https://cetuk.co.uk/wp-content/uploads/2023/11/Image_20231111122702.jpg",
        project_name: "TijaPOS",
        project_description: "Une application de gestion de ventes dans une boutique, ou dans un autre point de vente",
        project_domaine: ("https://tijapos.").concat(app_ns),
        auther: (app_short_name).concat(" ").concat(app_name)
    },
    {
        key: 3,
        image: "https://cetuk.co.uk/wp-content/uploads/2023/11/Image_20231111122702.jpg",
        project_name: "TijaStore",
        project_description: "Une application de gestion de stock, suivi de stock, management de ressources et bien d'autres ",
        project_domaine: ("https://tijastore.").concat(app_ns),
        auther: (app_short_name).concat(" ").concat(app_name)
    }
]