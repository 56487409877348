import * as React from 'react'
import { app_name } from '../utils/utils'

export const ServicesComponent = () => {
    return (
        <section id="services" className="service-area pt-90">
            <div className="container">
                <div className="service">
                    <div className="row no-gutters justify-content-center">
                        <div className="col-lg-4 col-md-7">
                            <div className="single-services wow fadeIn"
                                data-wow-duration="1s" data-wow-delay="0.2s">
                                <div className="services-shape"></div>
                                <div className="services-separator"></div>

                                <div
                                    className="services-items services-color-1 d-sm-flex">
                                    <div className="services-icon">
                                        <i className="lni-layout"></i>
                                    </div>
                                    <div className="services-content media-body">
                                        <h5 className="title">Conception de sites Web</h5>
                                        <p className="text">{app_name} conçoit des sites web sur mesure, modernes et adaptés à vos besoins.
                                            {/* Offrez à vos clients une expérience en ligne unique et professionnelle. */}
                                        </p>
                                    </div>
                                </div>
                                {/* <!-- services items --> */}
                            </div>
                            {/* <!-- single services --> */}
                        </div>
                        <div className="col-lg-4 col-md-7">
                            <div
                                className="single-services services-active wow fadeIn"
                                data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="services-shape"></div>
                                <div className="services-separator"></div>

                                <div
                                    className="services-items services-color-2 d-sm-flex">
                                    <div className="services-icon">
                                        <i className="lni-vector"></i>
                                    </div>
                                    <div className="services-content media-body">
                                        <h5 className="title">Conception graphique</h5>
                                        <p className="text">{app_name} crée des designs graphiques sur mesure pour refléter votre identité visuelle.
                                            {/* Nous combinons créativité et expertise pour offrir des solutions esthétiques percutantes. */}
                                        </p>
                                    </div>
                                </div>
                                {/* <!-- single services --> */}
                            </div>
                            {/* <!-- single services --> */}
                        </div>
                        <div className="col-lg-4 col-md-7">
                            <div className="single-services wow fadeIn"
                                data-wow-duration="1s" data-wow-delay="0.6s">
                                <div className="services-shape"></div>
                                <div className="services-separator"></div>

                                <div
                                    className="services-items services-color-3 d-sm-flex">
                                    <div className="services-icon">
                                        <i className="lni-blackboard"></i>
                                    </div>
                                    <div className="services-content media-body">
                                        <h5 className="title">Consultance</h5>
                                        <p className="text">{app_name} offre des services de consultance en informatique pour optimiser la transformation numérique de votre entreprise.
                                            {/* Nous vous aidons à trouver des solutions technologiques adaptées à vos besoins. */}
                                        </p>
                                    </div>
                                </div>
                                {/* <!-- single services --> */}
                            </div>
                            {/* <!-- single services --> */}
                        </div>
                    </div>
                    {/* <!-- row --> */}
                </div>
                {/* <!-- service --> */}
            </div>
            {/* <!-- container --> */}
        </section>
    )
}