import * as React from 'react'
import about from '../assets/images/about.jpg'
import { app_name, app_slogan_1, app_slogan_2 } from '../utils/utils'

export const AboutComponent = () => {
    return (
        <section id="about" className="about-area pt-110 pb-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="about-title text-center wow fadeInUp"
                            data-wow-duration="1s" data-wow-delay="0.3s">
                            <h6 className="welcome">Bienvenue</h6>
                            <h3 className="title"><span>Tout savoir </span> à propos de {app_name}</h3>
                        </div>
                    </div>
                </div>
                {/* <!-- row --> */}
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-image mt-50 wow fadeInLeftBig"
                            data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src={about} alt="About vector image" />
                        </div>
                        {/* <!-- about image --> */}
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content mt-50 wow fadeInRightBig"
                            data-wow-duration="1s" data-wow-delay="0.5s">
                            <h3 className="title">Apprenez-en un peu <br /> plus sur nous
                            <br /> et l'industrie.</h3>
                            <ul className="about-line">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                            <p className="text">{app_slogan_1} <br /> <br /> {app_slogan_2}</p>
                            <div className="about-counter pt-20">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div
                                            className="single-counter counter-color-1 mt-30 d-flex">
                                            <div className="counter-shape">
                                                <span className="shape-1"></span>
                                                <span className="shape-2"></span>
                                            </div>
                                            <div
                                                className="counter-content media-body">
                                                <span
                                                    className="counter-count"><span
                                                        className="counter">350</span>+</span>
                                                <p className="text">Clients</p>
                                            </div>
                                        </div>
                                        {/* <!-- single counter --> */}
                                    </div>
                                    <div className="col-sm-6">
                                        <div
                                            className="single-counter counter-color-2 mt-30 d-flex">
                                            <div className="counter-shape">
                                                <span className="shape-1"></span>
                                                <span className="shape-2"></span>
                                            </div>
                                            <div
                                                className="counter-content media-body">
                                                <span
                                                    className="counter-count"><span
                                                        className="counter">17</span>+</span>
                                                <p className="text">Projets effectués</p>
                                            </div>
                                        </div>
                                        {/* <!-- single counter --> */}
                                    </div>
                                </div>
                                {/* <!-- row --> */}
                            </div>
                            {/* <!-- about counter --> */}
                        </div>
                        {/* <!-- about content --> */}
                    </div>
                </div>
                {/* <!-- row --> */}
            </div>
            {/* <!-- container --> */}
        </section >
    )
}