import * as React from 'react'
import { __projects } from '../utils/staticsdata'
import logo from '../assets/images/keyicon.png'
import { app_name } from '../utils/utils'

export const ProjectsComponent = () => {
    return (
        <section id="projects" className="blog-area pt-115 pb-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4">
                        <div
                            className="section-title text-center pb-20 wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay="0.3s"
                            style={{
                                visibility: "visible",
                                animationDuration: "1s",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp",
                            }}
                        >
                            <h6 className="sub-title">Nos produits</h6>
                            <h4 className="title">
                                Dernières <span>mis à jour <span>{ }</span></span>
                            </h4>
                        </div>
                        {/* section title */}
                    </div>
                </div>
                {/* row */}
                <div className="row justify-content-center">
                    {__projects.map(project => {
                        const { auther, image, key, project_description, project_name, project_domaine } = project
                        return (
                            <div className="col-lg-4 col-md-6 col-sm-8" key={key}>
                                <div
                                    className="single-blog mt-30 wow fadeInUpBig"
                                    data-wow-duration="1s"
                                    data-wow-delay="0.4s"
                                    style={{
                                        visibility: "visible",
                                        animationDuration: "1s",
                                        animationDelay: "0.4s",
                                        animationName: "fadeInUpBig",
                                    }}
                                >
                                    <div className="blog-image">
                                        <a href={project_domaine}>
                                            <img
                                                src={image}
                                                alt={`${project_name} logo`}
                                            />
                                        </a>
                                    </div>
                                    <div className="blog-content">
                                        <h4 className="blog-title txt-primary">
                                            <a href={project_domaine}>
                                                {project_name}
                                                <p>{project_description}</p>
                                            </a>
                                        </h4>
                                        <div className="blog-author d-flex align-items-center">
                                            <div className="author-image">
                                                <img
                                                    src={logo}
                                                    alt="author"
                                                    style={{ width: 45 }}
                                                />
                                            </div>
                                            <div className="author-content media-body">
                                                <h6 className="sub-title txt-primary">Auteur {">"} </h6>
                                                <p className="text">{auther}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* single blog */}
                            </div>
                        )
                    })}
                </div>
                {/* row */}
            </div>
            {/* container */}
        </section>
    )
}